const strings = {
  ptBR: {
    home: {
      callingText: 'Aqui seu problema tem solução!',
      about: 'Sobre nós',
      contact: 'Contato',
    },
    about: {},
    field: {},
    contact: {
      mail: 'contato@leliseng.com',
      cellphone: '+55 (31) 99601-6800',
      phone: '+55 (31) 3568-5412',
    },
    general: {
      brand: 'Lelis Engenharia',
      developer: '@DanielHLelis',
    },
    headerFooter: {
      routes: {
        home: 'Home',
        about: 'Sobre',
        customers: 'Clientes',
        field: 'Atuação',
        contact: 'Contato',
      },
      location: 'Belo Horizonte - MG, Brasil',
      phoneLabel: 'Telefone',
      mailLabel: 'E-mail',
      copyright: 'Lelis Engenharia Ltda. © 2019 - 2020', // * Aprimorar
      producedBy: 'Produzido por: ',
    },
  },
}

export { strings }

export default strings
