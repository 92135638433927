import React from 'react'
import styled from '@emotion/styled'

import { SEO } from '../../utils'

import Header from '../../core/Header'
import Footer from '../../core/Footer'

import '../../../assets/styles/index.scss'

export function DefaultMaster({ children, ...props }) {
  return (
    <>
      <SEO {...props} />
      <IsolateFooter>
        <Header />
        {children}
      </IsolateFooter>
      <Footer />
    </>
  )
}

const IsolateFooter = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

export default DefaultMaster
