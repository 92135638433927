const constants = {
  headerHeight: '80px',
  sizes: {
    maxWidth: 1600,
    desktop: 1200,
    medium: 900,
    small: 600,
  },
}

export { constants }

export default constants
