const core = {
  light: '#f9f9fa',
  lighter: '#ffffff',

  darkish: '#272727',
  dark: '#222222',
  darker: '#1c1c1c',

  transparent: '#00000000',

  primary: '#262c84',
  secondary: '#348732',
  thirtiary: '#683796',

  red: '#dc3545',
  cyan: '#17a2b8',
  green: '#28a745',
  yellow: '#ffc107',
}

const colors = {
  core,

  header: {
    background: core.darkish,
    text: core.light,
  },

  footer: {
    background: core.darker,
    text: core.light,
  },

  dark: {
    background: core.dark,
    headers: core.lighter,
    text: core.light,
  },

  light: {
    background: core.light,
    headers: core.darker,
    text: core.dark,
  },

  warning: core.yellow,
  error: core.red,
  success: core.green,
  info: core.cyan,
}

export { core, colors }

export default colors
