import React from 'react'
import { Link } from 'gatsby'

import { StyledLogo, Wrapper, BrandWrapper } from './styles'
import { NavDesktop, NavItem, NavMobile } from './Nav'
import strings from '../../../config/strings'
import { BrandText } from '../Logo'

export function Header() {
  const routes = {
    '/': strings.ptBR.headerFooter.routes.home,
    '/sobre': strings.ptBR.headerFooter.routes.about,
    '/atuacao': strings.ptBR.headerFooter.routes.field,
    '/clientes': strings.ptBR.headerFooter.routes.customers,
    '/contato': strings.ptBR.headerFooter.routes.contact,
  }

  const renderNavItems = routes => {
    return Object.keys(routes).map(to => (
      <NavItem key={to} to={to}>
        {routes[to]}
      </NavItem>
    ))
  }

  return (
    <Wrapper>
      <NavMobile>{renderNavItems(routes)}</NavMobile>
      <BrandWrapper className="brand">
        <Link to="/">
          <StyledLogo />
          <BrandText />
        </Link>
      </BrandWrapper>
      <NavDesktop>{renderNavItems(routes)}</NavDesktop>
    </Wrapper>
  )
}

export default Header
