import React from 'react'
import {
  Wrapper,
  RightWrapper,
  LeftWrapper,
  BrandWrapper,
  StyledLogo,
  Separator,
  LeftContent,
} from './styles'
import strings from '../../../config/strings'
import { BrandText } from '../Logo'

export function Footer() {
  return (
    <Wrapper>
      <LeftWrapper>
        <BrandWrapper>
          <StyledLogo />
          <BrandText />
        </BrandWrapper>
        <Separator />
        <LeftContent>
          <span>{strings.ptBR.headerFooter.location}</span>
          <span>
            {strings.ptBR.headerFooter.phoneLabel}: {strings.ptBR.contact.phone}
            <br />
            {strings.ptBR.headerFooter.mailLabel}: {strings.ptBR.contact.mail}
          </span>
        </LeftContent>
      </LeftWrapper>
      <RightWrapper>
        <p>
          {strings.ptBR.headerFooter.producedBy +
            strings.ptBR.general.developer}
        </p>
        <p>{strings.ptBR.headerFooter.copyright}</p>
      </RightWrapper>
    </Wrapper>
  )
}

export default Footer
