import React from 'react'
import { Link } from 'gatsby'

import styled from '@emotion/styled'
import { keyframes, css } from '@emotion/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import Logo from '../Logo'

import { colors } from '../../../config/colors'
import { fontSizes } from '../../../config/typography'
import { spacings } from '../../../config/spacings'
import constants from '../../../config/constants'

// * Mobile definitions

const mobileLimit = constants.sizes.medium

const enableMobile = css`
  @media (min-width: ${mobileLimit + 1}px) {
    display: none;
  }
`
const disableMobile = css`
  @media (max-width: ${mobileLimit}px) {
    display: none;
  }
`

// * Logo

export const StyledLogo = styled(Logo)`
  height: ${fontSizes.large}rem; // ! May change
  margin-right: 0.75rem;
`

export const BrandWrapper = styled.div`
  @media (max-width: ${mobileLimit}px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`

// * Header

export const Wrapper = styled.header`
  height: ${constants.headerHeight};

  padding: 0 ${spacings.medium}rem;

  background: ${colors.header.background};
  color: ${colors.header.text};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;

  & > div > a {
    display: flex;
    align-items: center;
  }
`

// * Nav

export const StyledNav = styled.nav`
  ${disableMobile}

  ul {
    list-style: none;
    li {
      display: inline-block;

      /* width: 128px; */
      padding: ${spacings.default}rem;

      text-align: center;
      text-transform: uppercase;

      font-size: ${fontSizes.smaller};
    }
  }
`

export const StyledLink = styled(Link)`
  padding: ${spacings.smaller}rem 0;

  font-size: ${fontSizes.smaller}rem; // ! May change

  border-bottom: 0px solid transparent;

  transition: border-bottom 0.2s ease-out;

  &:active {
    border-bottom: 3px solid white;
  }

  &:hover:not(.active) {
    border-bottom: 1px solid white;
  }

  &.active {
    border-bottom: 2px solid white;
  }
`

// * Nav: mobile

export const BurguerIcon = props => <FontAwesomeIcon icon={faBars} {...props} />

export const StyledBurguerIcon = styled(BurguerIcon)`
  ${enableMobile}
  font-size: ${fontSizes.medium}rem;
  &:hover {
    cursor: pointer;
  }
`

export const CloseIcon = props => (
  <FontAwesomeIcon icon={faArrowLeft} {...props} />
)

export const StyledCloseIcon = styled(CloseIcon)`
  align-self: flex-start;

  padding: ${spacings.default};

  font-size: ${fontSizes.default}rem;

  &:hover {
    cursor: pointer;
  }
`

export const MobileMenuContainer = styled.nav`
  z-index: 7;

  height: 100vh;
  max-width: 100vw;
  min-width: 375px;
  padding: ${spacings.default}rem ${spacings.large}rem;

  background: ${colors.header.background};

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: fixed;
  top: 0;
  left: -110%;

  transition: left 0.2s ease-in-out;

  ${enableMobile}

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0;
    li {
      display: inline-block;

      /* width: 128px; */
      padding: ${spacings.large}rem;

      text-align: center;
      text-transform: uppercase;

      font-size: ${fontSizes.smaller};
    }
  }

  &.show {
    left: 0%;
    &:after {
      z-index: 6;
      height: 100vh;
      width: 100vw;

      position: fixed;
      left: 0;
      top: 0;

      background: #000;
      opacity: 40%;
    }
  }
`

const DarkenerTransition = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 60%;
  }
`

export const Darkener = styled.div`
  z-index: 6;
  height: 100vh;
  width: 100vw;
  display: none;

  position: fixed;
  left: 0;
  top: 0;

  background: #000;
  opacity: 0%;

  cursor: pointer;

  &.show {
    animation: ${DarkenerTransition} 0.2s;
    display: block;
    opacity: 60%;
  }
`
