import React from 'react'
import styled from '@emotion/styled'
import { fontSizes } from '../../config/typography'
import strings from '../../config/strings'

export function Logo(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 560.42 396.85"
    >
      <rect
        x="154.09"
        y="244.33"
        width="280.62"
        height="280.62"
        transform="translate(-199.93 134.61) rotate(-45)"
        style={{ fill: '#232a7c' }}
      />
      <polygon
        points="68 198.43 152.87 113.56 198.46 0 118.71 79.41 0 198.12 0.31 198.43 0 198.73 118.71 317.44 198.46 396.85 152.87 283.29 68 198.43"
        style={{ fill: '#35803d' }}
      />
      <polygon
        points="560.42 198.12 441.71 79.41 361.96 0 407.55 113.56 492.42 198.43 407.55 283.29 361.96 396.85 441.71 317.44 560.42 198.73 560.11 198.43 560.42 198.12"
        style={{ fill: '#35803d' }}
      />
    </svg>
  )
}

export const BrandText = props => (
  <BrandTextWrapper {...props}>{strings.ptBR.general.brand}</BrandTextWrapper>
)

const BrandTextWrapper = styled.span`
  font-size: ${fontSizes.smallish}rem;
`

export default Logo
