import React from 'react'

import styled from '@emotion/styled'
import { sheets } from '../../config/typography'

import spacings from '../../config/spacings'
import colors from '../../config/colors'

// import styled from '@emotion/styled'
// import {
//   css,
//   keyframes
// } from '@emotion/core'

/*
  * Global repeated styles that doesn't match as an full comnponent should be here
  * Only emotion's styled components and emotion's css blocks should be in this file
  * And simple property binding 

  DRY
*/

export const TextBlock = styled.p`
  ${sheets.default.default};

  text-indent: 2.25em;
  text-align: justify;
`

export const Darkener = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background: ${({ baseColor = '#000' }) => baseColor};
  opacity: ${({ intensity = '50%' }) => intensity};
  z-index: -1;
`

const SeparatorContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  grid-gap: ${spacings.small}rem;

  height: 0.25rem;

  & > div {
    width: ${spacings.larger}rem;
    background: ${colors.core.primary};
  }

  margin: ${spacings.medium}rem 0;
`

export const Separator = () => {
  return (
    <SeparatorContainer>
      <div></div>
      <div></div>
      <div></div>
    </SeparatorContainer>
  )
}
