import styled from '@emotion/styled'
import spacings from '../../../config/spacings'
import colors from '../../../config/colors'
import Logo from '../Logo'
import { fontSizes } from '../../../config/typography'
import constants from '../../../config/constants'

const mobileLimit = constants.sizes.medium

export const Wrapper = styled.footer`
  width: 100%;

  background: ${colors.footer.background};
  color: ${colors.footer.text};

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${spacings.medium}rem ${spacings.larger}rem;

  @media (max-width: ${mobileLimit}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

// Left sector

export const StyledLogo = styled(Logo)`
  height: ${fontSizes.largest}rem;
  margin-right: ${spacings.small}rem;

  @media (max-width: ${mobileLimit}px) {
    margin-right: 0;
    margin-bottom: ${spacings.small}rem;
  }
`

export const BrandWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: ${fontSizes.smallish}rem;

  @media (max-width: ${mobileLimit}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${mobileLimit}px) {
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  line-height: 1.5;

  font-size: ${fontSizes.smaller}rem;

  & > span {
    /* margin: ${spacings.smallest}rem 0; */
  }

  @media (max-width: ${mobileLimit}px) {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`

export const Separator = styled.div`
  height: ${fontSizes.largest + spacings.medium * 2}rem;
  width: 1px;

  margin: 0 ${spacings.medium}rem;

  background: ${colors.footer.text};

  @media (max-width: ${mobileLimit}px) {
    margin: ${spacings.small}rem 0;

    height: 1px;
    width: 100%;
  }
`

export const RightWrapper = styled.div`
  align-self: flex-end;

  text-align: right;
  font-size: ${fontSizes.smallest}rem;

  @media (max-width: ${mobileLimit}px) {
    align-self: auto;
    text-align: center;

    margin-top: ${spacings.default}rem;
  }
`
