import React, { useState } from 'react'

import {
  StyledNav,
  StyledLink,
  StyledBurguerIcon,
  MobileMenuContainer,
  Darkener,
  StyledCloseIcon,
} from './styles'

function NavMobile(props) {
  const [active, setActive] = useState(false)

  const close = () => setActive(false)
  const open = () => setActive(true)

  return (
    <>
      <StyledBurguerIcon onClick={open} />
      <MobileMenuContainer className={active ? 'show' : ''}>
        <StyledCloseIcon onClick={close} />
        <ul>{props.children}</ul>
      </MobileMenuContainer>
      <Darkener onClick={close} className={active ? 'show' : ''} />
    </>
  )
}

const NavDesktop = props => (
  <StyledNav>
    <ul>{props.children}</ul>
  </StyledNav>
)

const NavItem = props => (
  <li>
    <StyledLink
      {...props}
      activeClassName={`active ${props.activeClassName || ''}`}
    >
      {props.children}
    </StyledLink>
  </li>
)

export { NavDesktop, NavMobile, NavItem }
