const baseSpacing = 0.25

const spacings = {
  smallest: baseSpacing,
  smaller: baseSpacing * 2,
  small: baseSpacing * 3,
  default: baseSpacing * 4,
  medium: baseSpacing * 6,
  large: baseSpacing * 8,
  larger: baseSpacing * 9,
  largest: baseSpacing * 12,
}

export { baseSpacing, spacings }

export default spacings
