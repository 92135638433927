import { css } from '@emotion/core'

const fontFamilies = {
  default:
    "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Open Sans', 'Helvetica Neue', sans-serif",
  headers: "'Rockwell', serif",
}

const baseSize = 1.5

const fontSizes = {
  baseSize,
  smallest: 0.5 * baseSize,
  smaller: 0.625 * baseSize,
  small: 0.75 * baseSize,
  smallish: 0.875 * baseSize,
  default: baseSize,
  medium: 1.25 * baseSize,
  largish: 1.5 * baseSize,
  large: 2 * baseSize,
  larger: 2.25 * baseSize,
  largest: 3 * baseSize,
  huge: 4 * baseSize,
}

function buildSheets() {
  let ret = {}
  for (let key in fontFamilies) {
    ret[key] = {}
    for (let fs in fontSizes) {
      ret[key][fs] = css`
        font-family: ${fontFamilies[key]};
        font-size: ${fontSizes[fs]}rem;
      `
    }
  }
  return ret
}

const sheets = buildSheets()

export { fontFamilies, fontSizes, sheets }
